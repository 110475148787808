/* @import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html { 

}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #111112 url(./assets/stars.png); 
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
  text-decoration: none;
}

.st-logo {
  width: 50px;
  margin-right: 15px;
  cursor: pointer;
}

.navbar {
  background: none;
  box-shadow: none;
}

.welcome {
  color: #ffff;
  align-items: center;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.05rem;
}

.bg-img {
  background-color: #19171d;
  opacity: 0;
   height: 100%;
   width: 100%;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   position: fixed;
   z-index: -1;
}

.card-container {
  display: flex;
  padding-top: 10%;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}

.card-outer {
  width: 400px;
  height: 300px;
  background-color: #1f1f21;
  // background-color: #ffffff11;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  // opacity: 0.9;
  // backdrop-filter: blur(0.15rem);
}

.card-inner {
  width: 100%;
  height: 100%;
  /* background-color: mediumturquoise; */
}

.card-img {
  width: 100%;
  height: 80%;
  border-radius: 20px 20px 0px 0px;
  overflow: hidden;
  background: linear-gradient(#5a5861, #1f1f21);
  // background: linear-gradient(#ffffff55, #1f1f21 60%);
}

.error-field-outer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-field-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  align-items: center;
  padding: 20px;
  width: 450px;
  border-radius: 8px;
  position: absolute;
  &__text-field {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  &__home-button {
    text-align: left;
    // position: absolute;
    z-index: 1;
    margin-right: 280px;
    text-transform: lowercase;
    padding-top: 5px;
    padding-bottom: 75px;
  }
  &__title{
    color: #212121;
    font-size: 42px;
    font-weight: 900;
  }
  &__text{
    color: #212121;
    font-size: 22px;
    font-weight: 600;
  }
  &__crashrocket {
    width: 100%;
    position: absolute; 
    bottom: -1px;
    // left: -2px;
    border-radius: 8px;
  }
}

.main {
  height: 100%;
  color: #ffff;
}

.main-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.my-graph {
  background-color: lightsteelblue;
  height: 400px;
}

.navbar {
  // background-color: #424242;
  // box-shadow: rgba(255, 255, 255, 0.05) 0px 5px 15px;
}

.title-text {
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: #fafafa;
  /* background-color: mediumvioletred; */
}


.logo-loading {
  width: 252px;
  height: 250px;
  background: url(./assets/loading-icon.png); 
  background-size: cover;
  animation:
      logo-loading-spin 4.5s cubic-bezier(0,0.69,1,0.31) infinite both;
}

@keyframes logo-loading-spin { 
  50% { transform:rotate(180deg); }
  100% { transform:rotate(360deg); }
}


